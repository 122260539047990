import {useCallback, useRef, useState} from 'react';

import {getImageData} from '~utils/imageData';
import SpeakerIcon from '~assets/icons/speaker.svg';
import SpeakerMutedIcon from '~assets/icons/speaker-muted.svg';
import {HeroVideoSection} from '~interfaces/graphql/HeroVideoSection';
import Text from '~components/atoms/Text';
import CustomLink from '~components/atoms/CustomLink';
import {If} from '~components/atoms/If';

import {Container, MuteBtn, StyledLink, TextAndButton, Video} from './styles';

const HeroVideo = ({poster, video, heroText, heroButton}: HeroVideoSection) => {
    const playerRef = useRef<HTMLVideoElement>(null);
    const [isMuted, setIsMuted] = useState(true);

    const setMute = useCallback((value) => {
        if (playerRef.current) {
            playerRef.current.muted = value;
            setIsMuted(value);
        }
    }, []);

    return (
        <Container>
            <Video
                ref={playerRef}
                // @ts-ignore
                poster={poster ? getImageData(poster).src : ''}
                src={video.data.attributes.url}
                loop
                muted
                playsInline
                autoPlay
            />
            <MuteBtn onClick={() => setMute(!isMuted)}>
                {isMuted ? <SpeakerMutedIcon /> : <SpeakerIcon />}
            </MuteBtn>
            <If condition={heroText && heroButton}>
                <TextAndButton>
                    <Text type="sectionTitle" bold>
                        {heroText}
                    </Text>
                    <CustomLink
                        href={heroButton?.url || ''}
                        target={heroButton?.target}
                    >
                        <StyledLink>
                            {heroButton?.icon && (
                                <img {...getImageData(heroButton?.icon)} />
                            )}
                            {heroButton?.text}
                        </StyledLink>
                    </CustomLink>
                </TextAndButton>
            </If>
        </Container>
    );
};

export default HeroVideo;
